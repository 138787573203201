.search-unit-v1 {

	.search-unit-title {
		display: flex;
		align-items: center;

		a {
			font-family: Marydale;
			font-size: 1.5rem;
		}

		iframe {
			width: 52px;
			height: 52px;
			border: none;
			margin: 0 10px 0 0;
		}

	}

	.unit-row {
		padding: 15px;
		background-color: #fff;
		transition: 0.3s;

		position: relative;

		&:hover {
			box-shadow: 0 0px 0px 1px rgb(0 102 157);
		}

		a {
			color: var(--font-color-main);
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}


			&:hover {
				text-decoration: none;
			}
		}

		.offer-price-box {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: right;

			@media (max-width:767px) {
				text-align: left;
			}

			.more-price-info {
				font-size: 12px;
				display: inline-block;
				letter-spacing: -0.4px;
			}
		}

		.facility-image {
			display: none;
			position: absolute;
			right: 10px;
			top: 10px;

			@media (min-width:1200px) {
				display: block;
			}

			img {
				width: 140px;
				height: auto;

				&.senden {
					width: 165px;
				}
			}
		}

	}

	.img-col {
		position: relative;
		margin-left: 15px;

		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			min-height: 212px;
		}

		.calendar-link {
			.btn-lg {
				background: var(--color-primary);
				color: #fff;
				font-size: 14px;
				border: none;
				padding: 5px 10px;
				border-radius: 0;
				position: absolute;
				top: 32px;
				right: -15px;
				display: flex;
				align-items: center;

				&:hover {
					background: #c3bca6;
				}

				i {
					margin-left: 5px;
					font-size: 11px;
					display: none;
				}
			}
		}
	}

	.info-col {

		position: relative;
		padding-left: 30px;

		@media (max-width: 1199px) {
			padding-top: 15px;
			padding-left: 15px;
		}
	}

	.facility-image2 {
		display: none;
		position: absolute;
		right: 10px;
		top: 5px;

		@media (max-width:1199px) {
			display: block;
		}

		@media (max-width:560px) {
			display: none;
		}

		img {
			width: 140px;
			height: auto;

			&.senden {
				width: 160px;
			}
		}
	}

	.highlights {
		padding-left: 5px;

		.fa {
			padding-right: 15px;


		}

		.fa-stack {
			height: 33px !important;
			margin-left: -5px;
			width: 33px !important;
		}

	}

	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}

	.fav-icon {
		position: absolute;
		left: 10px;
		top: 10px;
		color: #fff;
		cursor: pointer;
	}

	.properties-row {}

	.unit-properties {

		li {
			padding-right: 20px;
			display: inline-block;

			.fa {
				width: 30px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}
		}
	}

	.unit-price-col {
		display: flex;
		align-items: end;

		.unit-act-row {
			display: table-cell;
			vertical-align: bottom;
		}
	}

	.rent {
		text-align: center;
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
		color: #c3bca6;
		font-size: 14px;
		margin: 0 7px 0 0;
	}

	.discount-price {
		font-weight: 600;
		margin: 0;
		color: #fff;
		font-size: 16px;
		line-height: normal;

		.reducedprice {
			color: var(--color-white);
		}
	}

	.prices {
		text-align: center;

		.h3 {
			color: #00669c;
			font-weight: 700;
		}

		@media (max-width: 1200px) {
			text-align: right;
		}

		a {
			/*	background: #00669c;
			background: var(--color-primary);
			color: var(--font-color-main);
			font-size: 14px;
			border: none;
			padding: 5px 10px;
			border-radius: 0;
			/*position: absolute;
			position: inherit;
			top: 32px;
			right: -15px;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;*/
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-red);
			color: var(--color-white);
			padding: 10px 15px;
			border: 1px dashed var(--color-white);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding-top: 8px;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}

	.alternatives {
		margin-top: 15px;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 1px;
		background-color: var(--color-grey-light);

		.altheading {
			font-weight: 600;
			color: var(--color-red);

		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-red);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-red);
			font-weight: 600;
		}

	}



}